<i18n>
{
  "th": {
    "drop": "วางไฟล์เพื่ออัพโหลด",
    "btn": {
      "upload": {
        "image": "เลือกรูปภาพ",
        "file": "เลือกไฟล์"
      },
      "save": "บันทึก",
      "cancel": "ยกเลิก"
    }
  },
  "en": {
    "drop": "Drop files to upload",
    "btn": {
      "upload": {
        "image": "Select image",
        "file": "Select file"
      },
      "save": "Save",
      "cancel": "Cancel"
    }
  }
}
</i18n>
<template>
  <div
    class="app-upload">
    <div
      v-show="$refs.upload && $refs.upload.dropActive"
      class="drop-active">
      <h3>{{ $t('drop') }}</h3>
    </div>
    <div
      v-show="!edit"
      class="upload-upload">
      <v-hover v-slot="{ hover }">
        <div class="text-center p-2">
          <label
            class="upload-label d-flex flex-column justify-center"
            :for="`${name}-upload`">
            <div v-if="value">
              <v-img
                v-if="acceptType === 'image'"
                :src="value">
                <v-expand-transition>
                  <div
                    v-if="hover && showDelete"
                    class="overlay d-flex align-center justify-space-around transition-fast-in-fast-out">
                    <v-btn
                      class="mx-2"
                      color="error"
                      fab
                      dark
                      small
                      depressed
                      @click="openDialog()">
                      <v-icon
                        class="pa-1 pt-3"
                        dark>
                        $trashwhite
                      </v-icon>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-img>
              <div v-else>
                {{ value }}
              </div>
            </div>
            <div v-else>
              <v-icon small>mdi-plus</v-icon>
            </div>
            <a>{{ acceptType === 'image' ? $t('btn.upload.image') : $t('btn.upload.file') }}</a>
          </label>
        </div>
      </v-hover>
    </div>
    <div class="upload-input text-center p-2">
      <file-upload
        ref="upload"
        v-model="files"
        :extensions="extensions"
        :accept="accept"
        :name="`${name}-upload`"
        :drop="!edit"
        @input-filter="inputFilter"
        @input-file="inputFile" />
    </div>
    <div
      v-if="files.length && edit"
      class="upload-edit">
      <div
        v-if="files.length"
        class="upload-edit-image">
        <v-img
          v-if="acceptType === 'image'"
          ref="editImage"
          :src="files[0].url" />
        <div v-else>
          {{ files[0].name }}
        </div>
      </div>
      <div
        v-if="error"
        class="error--text pa-1">
        {{ error }}
      </div>
      <div class="text-center pa-3">
        <v-btn
          type="button"
          class="mr-1"
          small
          outlined
          @click.prevent="$refs.upload.clear">
          {{ $t('btn.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          small
          depressed
          @click.prevent="editSave($event)">
          {{ $t('btn.save') }}
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div
        v-if="error"
        class="error--text pa-1">
        {{ error }}
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      scrollable>
      <alert-remove-card
        @confirm="remove()"
        @close="closeDialog()" />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
// import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import AlertRemoveCard from '@/components/Card/AlertRemoveCard.vue'

export default {
  components: {
    FileUpload,
    AlertRemoveCard
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: () => null
    },
    confirm: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    acceptType: {
      type: String,
      validator: (val) => ['image', 'excel'].includes(val),
      default: 'image'
    }
  },
  data () {
    return {
      dialog: false,
      files: [],
      edit: false,
      cropper: false,
      error: ''
    }
  },
  computed: {
    extensions () {
      switch (this.acceptType) {
        case 'excel':
          return 'csv,xls,xlsx'
        default:
          return 'gif,jpg,jpeg,png,webp'
      }
    },
    accept () {
      switch (this.acceptType) {
        case 'excel':
          return '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
        default:
          return 'image/png,image/gif,image/jpeg,image/webp'
      }
    }
  },
  // watch: {
  //   edit (value) {
  //     if (value) {
  //       this.$nextTick(() => {
  //         if (!this.$refs.editImage) {
  //           return
  //         }
  //         const cropper = new Cropper(this.$refs.editImage, {
  //           aspectRatio: 1 / 1,
  //           viewMode: 1
  //         })
  //         this.cropper = cropper
  //       })
  //     } else if (this.cropper) {
  //         this.cropper.destroy()
  //         this.cropper = false
  //       }
  //   }
  // },
  methods: {
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    remove () {
      this.$emit('remove', true)
      this.closeDialog()
    },
    editSave () {
      const file = this.files[0]
      this.$emit('submit', file)
      this.edit = false
      // const oldFile = this.files[0]
      // const binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      // const arr = new Uint8Array(binStr.length)
      // for (let i = 0; i < binStr.length; i++) {
      //   arr[i] = binStr.charCodeAt(i)
      // }
      // const file = new File([arr], oldFile.name, { type: oldFile.type })
      // this.$refs.upload.update(oldFile.id, {
      //   file,
      //   type: file.type,
      //   size: file.size,
      //   active: true
      // })
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(() => {
          if (this.confirm) {
            this.edit = true
          } else {
            this.editSave()
          }
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      this.error = null
      if (newFile && !oldFile) {
        if (this.acceptType === 'excel') {
          if (!/\.(csv|xls|xlsx)$/i.test(newFile.name)) {
            this.error = '* Your choice is not a excel'
            return prevent()
          }
        } else if (this.acceptType === 'image') {
          if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
            this.error = '* Your choice is not a picture'
            return prevent()
          }
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }
  }
}
</script>
<style scoped>
  img {
    width: 100%;
  }
</style>
<style>
.app-upload .upload-label {
  display:block;
  min-height: 200px;
  border: 2px dotted #c6c6c6;
}
.upload-input {
  display: none;
}
.app-upload .text-center .btn {
  margin: 0 .5rem
}
.app-upload .upload-edit-image {
  max-width: 100%
}
.app-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  opacity: .6;
  text-align: center;
  background: #000;
}
.app-upload .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.overlay {
  height: 100%;
  background-color: rgba(245, 245, 245, .96);
}
</style>
